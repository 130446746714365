import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Preferences} from '@capacitor/preferences';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AuthenticationResponse} from '../../model/AuthenticationResponse';
import {AuthRole} from '../../model/AuthRole';
import {ChangePasswordResult} from '../../model/ChangePasswordResult';
import {LoginInfo} from '../../model/LoginInfo';
import {User} from '../../model/User';
import {AlertService} from '../alert/alert.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _user = new BehaviorSubject<User>(null);
    captchaNeeded = new BehaviorSubject<boolean>(false);

    constructor(private httpClient: HttpClient,
                private alertService: AlertService, private router: Router) {
    }

    get user() {
        return this._user.value;
    }

    get IsAuthenticated(): Observable<boolean> {
        return this._user.asObservable().pipe(map(user => {
            if (user) {
                return !!user.token;
            } else {
                return false;
            }
        }));
    }

    hasRole(...authRoles: AuthRole[]) {
        return authRoles.some(authRole => this.user.roles.some(role => authRole === role));
    }

    loadIsCaptchaNeeded() {
        this.httpClient.get<boolean>(environment.backendURL + '/captcha-needed').subscribe(value => this.captchaNeeded.next(value));
    }

    login(loginInfo: LoginInfo) {
        const httpOptions = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('username', loginInfo.userName);
        urlSearchParams.append('password', loginInfo.password);
        if (loginInfo.captcha) {
            urlSearchParams.append('captcha', loginInfo.captcha);
        }
        const body = urlSearchParams.toString();
        return this.httpClient.post<AuthenticationResponse>(environment.backendURL + '/token', body, httpOptions)
            .pipe(tap(userData => this.setUserData(userData)));
    }

    logout() {
        this.httpClient.get<AuthenticationResponse>(environment.backendURL + '/logOut').subscribe(() => {
            this._user.next(null);
            this.router.navigate(['authentication']);
        });
    }

    private setUserData(userData: AuthenticationResponse) {
        this._user.next(
            new User(
                userData.token,
                userData.roles
            )
        );
        this.captchaNeeded.next(userData.captchaNeeded);
    }

    changePassword(oldPassword: string, newPassword: string) {
        const httpOptions = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + this._user.value.token,
            }
        };
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('oldPassword', oldPassword);
        urlSearchParams.append('newPassword', newPassword);
        const body = urlSearchParams.toString();

        return this.httpClient.post<ChangePasswordResult>(environment.backendURL + '/change-password', body, httpOptions);
    }

    storeLoginData(loginInfo: LoginInfo) {
        Preferences.set({
            key: 'loginInfo', value: JSON.stringify(loginInfo)
        });
    }

    get loginData() {
        return Preferences.get({key: 'loginInfo'});
    }
}
