<ion-content>
    <ion-grid class="ion-no-padding ion-no-margin">
        <ion-row class="row-margin">
            <ion-col class="ion-text-start">
                <ion-label class="title-label">
                    {{'allocation_network_point_visibility'|i18n}}
                </ion-label>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-list>
                    <ion-item lines="none" *ngFor="let networkPoint of networkPoints">
                        <ion-label>{{networkPoint.networkPointName}}</ion-label>
                        <ion-checkbox slot="start" [(ngModel)]="networkPoint.showOnMap"></ion-checkbox>
                    </ion-item>
                </ion-list>
            </ion-col>
        </ion-row>
        <ion-row class="ion-text-end">
            <ion-col>
                <ion-button fill="clear" class="font-bold cancelButton"
                            (click)="onCancel()">{{'button_cancel'|i18n|uppercase}}</ion-button>
                <ion-button fill="clear" class="font-bold" (click)="onOk()">{{'button_ok'|i18n|uppercase}}</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
