<ion-footer class="colored-background footer">
    <ion-grid>
        <ion-row class="ion-align-items-center">
            <ion-col size="8">
                <ion-label class="small_text">
                    {{'footer_all_rights_reserved'|i18n}}
                </ion-label>
            </ion-col>
            <ion-col size="3" class="ion-text-end">
                <ion-label class="small_text">
                    powered by
                </ion-label>
            </ion-col>
            <ion-col size="1" class="ion-no-padding">
                <img id="logo" (click)="onClickAstronLogo()" src="assets/images/astron_logo.png"/>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>
