import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {EntsogAllocationData} from "../../../model/EntsogAllocationData";

@Component({
    selector: 'phonee-visibility-modal',
    templateUrl: './visibility-modal.component.html',
    styleUrls: ['./visibility-modal.component.scss'],
})
export class VisibilityModalComponent implements OnInit {

    @Input() networkPoints: EntsogAllocationData[];

    constructor(private modalController: ModalController) {
    }

    ngOnInit() {

    }

    onOk() {
        this.modalController.dismiss(true);
    }

    onCancel() {
        this.modalController.dismiss();
    }
}
