import {AuthRole} from "./AuthRole";

export class User {

    constructor(private _token: string, private _roles: AuthRole[]) {
    }

    get token() {
        return this._token;
    }

    get roles() {
        return this._roles;
    }

}
