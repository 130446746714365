import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeHu from '@angular/common/locales/hu';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {I18nDatePipe, I18nModule, I18nNumberPipe} from '@astron/i18n';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {CallNumber} from '@ionic-native/call-number/ngx';

import {EmailComposer} from '@ionic-native/email-composer/ngx';
import {FingerprintAIO} from '@ionic-native/fingerprint-aio/ngx';
import {Screenshot} from '@ionic-native/screenshot/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {ErrorInterceptor} from './interceptor/ErrorInterceptor';
import {HeaderInterceptor} from './interceptor/HeaderInterceptor';
import {RefreshMapModalComponent} from './view/allocation/refresh-map-modal/refresh-map-modal.component';
import {VisibilityModalComponent} from './view/allocation/visibility-modal/visibility-modal.component';
import {AuthenticationPageModule} from './view/authentication/authentication.module';
import {InformativeModalComponent} from './view/authentication/informative-modal/informative-modal.component';

registerLocaleData(localeHu, 'hu');

@NgModule({
    declarations: [AppComponent, VisibilityModalComponent, RefreshMapModalComponent],
    imports: [
        AuthenticationPageModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        HttpClientModule,
        I18nModule.forRoot({
            dictionaryList: {
                path: 'assets/i18n',
                packages: [{
                        name: 'translations',
                        protected: false
                    }],
            },
            languages: ['en', 'hu'],
            defaultLocale: 'hu',
            defaultDictionary: 'translations',
            useDeprecatedLocaleHeader: false,
            debug: false,
        }),
        FormsModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        FingerprintAIO,
        CallNumber,
        EmailComposer,
        I18nNumberPipe,
        I18nDatePipe,
        AppVersion,
        Screenshot,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
