import {Component} from '@angular/core';
import {I18nService} from '@astron/i18n';
import {AuthService} from '../../../service/auth/auth.service';
import {Router} from "@angular/router";

@Component({
    selector: 'app-global-header',
    templateUrl: './global-header.component.html',
    styleUrls: ['./global-header.component.scss'],
})
export class GlobalHeaderComponent {

    menuDisabled: boolean = false;
    urlHierarchyRegexp: RegExp = /\/(?=.+)/;

    constructor(private i18nService: I18nService,
                private authService: AuthService,
                private router: Router) {
        // authService.IsAuthenticated.subscribe(value => this.menuDisabled = !value);
    }

    onLocaleSelected(locale: string) {
        if (!this.isSelected(locale)) {
            this.i18nService.changeLocale(locale);
        }
    }

    isSelected(locale: string) {
        return this.i18nService.locale === locale;
    }

    // workaround, mivel a navigációhoz mindenhol az Angular - os Router - t használjuk az Ionic - os navigáció helyett
    // és az ion-back-button által figyelt page stack - et az Angular Router nem frissíti
    showBackButton() {
        return this.router.url.split(this.urlHierarchyRegexp).length > 2;
    }

    getBackButtonRef() {
        const urlHierarchy: string[] = this.router.url.split(this.urlHierarchyRegexp);
        return urlHierarchy[urlHierarchy.length - 2];
    }
}
