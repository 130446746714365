<ion-content>
    <ion-grid>
        <ion-row class="row-margin">
            <ion-col class="ion-text-start">
                <ion-label class="title-label">
                    {{'allocation_refresh_map'|i18n}}
                </ion-label>
            </ion-col>
        </ion-row>
        <ion-row class="row-margin">
            <ion-col>
                <ion-item>
                    <ion-label>{{'allocation_start_date'|i18n}}</ion-label>
                    <ion-datetime displayFormat="YYYY.MM.DD" #startDatePicker
                                  [(ngModel)]="startDate" (ionChange)="startDateSelected()"
                                  [max]=maxDate
                                  cancelText="{{'button_cancel'|i18n}}"
                                  doneText="{{'button_ok'|i18n}}">
                    </ion-datetime>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="row-margin">
            <ion-col>
                <ion-item>
                    <ion-label>{{'allocation_end_date'|i18n}}</ion-label>
                    <ion-datetime displayFormat="YYYY.MM.DD" #endDatePicker [(ngModel)]="endDate"
                                  (ionChange)="endDateSelected()" [max]=maxDate
                                  cancelText="{{'button_cancel'|i18n}}"
                                  doneText="{{'button_ok'|i18n}}">
                    </ion-datetime>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="ion-text-end">
            <ion-col>
                <ion-button fill="clear" class="font-bold cancelButton"
                            (click)="onCancel()">{{'button_cancel'|i18n|uppercase}}</ion-button>
                <ion-button fill="clear" class="font-bold" (click)="onOk()"
                            [disabled]="!canRefresh()">{{'button_ok'|i18n|uppercase}}</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
