<ion-app>
    <ion-menu contentId="main-content">
        <ion-content class="menu-content">
            <ion-list>
                <ion-menu-toggle>
                    <ng-container *ngIf="isAuth | async">
                        <ion-item routerLink="forwards">{{'menu_forwards'|i18n}}</ion-item>
                        <ion-item routerLink="contracts">{{'menu_contracts'|i18n}}</ion-item>
                        <ion-item routerLink="new-contract"
                                  *ngIf="newContractVisible">{{'menu_new_contract'|i18n}}</ion-item>
                        <ion-item routerLink="allocation"
                                  *ngIf="allocationVisible">{{'menu_allocation'|i18n}}</ion-item>
                        <ion-item routerLink="change-password">{{'menu_change_password'|i18n}}</ion-item>
                        <ion-item button="true" (click)="onLogoutClick()">{{'menu_logout'|i18n}}</ion-item>
                    </ng-container>
                </ion-menu-toggle>

                <ion-item lines="none">{{'menu_theme'|i18n}}
                    <ion-toggle [(ngModel)]="themeService.darkTheme"></ion-toggle>
                </ion-item>

                <ng-container *ngIf="!isProduction">
                    <ion-item-group>
                        <ion-item-divider>
                            <ion-label>DEV:</ion-label>
                        </ion-item-divider>
                    </ion-item-group>

                    <ion-menu-toggle>
                        <ion-item routerLink="/authentication">/authentication</ion-item>
                        <ion-item routerLink="/maintenance">/maintenance</ion-item>
                    </ion-menu-toggle>
                </ng-container>
            </ion-list>
        </ion-content>
    </ion-menu>

    <app-global-header></app-global-header>
    <ion-content>
        <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-content>
    <app-global-footer></app-global-footer>

</ion-app>
