import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {I18nService} from '@astron/i18n';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {Platform} from '@ionic/angular';
import {take} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {AuthRole} from './model/AuthRole';
import {AuthService} from './service/auth/auth.service';
import {ThemeService} from './service/theme/theme.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

    get isAuth() {
        return this.authService.IsAuthenticated;
    }

    constructor(private platform: Platform,
                private splashScreen: SplashScreen,
                private statusBar: StatusBar,
                private i18nService: I18nService,
                private router: Router,
                private authService: AuthService,
                public themeService: ThemeService) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    ngOnInit(): void {
        this.authService.IsAuthenticated.pipe(take(1)).subscribe(isAuth => {
            // elindításkor jelenleg soha nincs bejelentkezve, de azért nézzük meg
            if (!isAuth) {
                this.router.navigateByUrl('authentication');
            }
        });
    }

    onLogoutClick() {
        this.authService.logout();
    }

    get allocationVisible() {
        return this.authService.hasRole(AuthRole.MANAGER, AuthRole.EXT_AUDITOR, AuthRole.AUDITOR);
    }

    get newContractVisible() {
        return this.authService.hasRole(AuthRole.EXT) && this.authService.hasRole(AuthRole.GBO, AuthRole.EO);
    }

    get isProduction() {
        return environment.production;
    }
}
