import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {I18nService} from '@astron/i18n';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {Market} from '@ionic-native/market';
import {AlertController} from '@ionic/angular';
import * as compareVersions from 'compare-versions';
import {ReplaySubject} from 'rxjs';
import * as semverDiff from 'semver-diff';
import {environment} from '../../environments/environment';
import {VersionDiff} from '../model/VersionDiff';
import {AlertService} from './alert/alert.service';
import {PlatformService} from './platform/platform.service';

@Injectable({
    providedIn: 'root'
})
export class VersionService {

    constructor(private appVersion: AppVersion,
                private alertService: AlertService,
                private alertController: AlertController,
                private i18n: I18nService,
                private platformService: PlatformService,
                private httpClient: HttpClient,
                private router: Router) {
    }

    public versionDiff = new ReplaySubject<VersionDiff>(1);

    async checkAppVersion(): Promise<VersionDiff> {
        if (this.platformService.isBrowser()) {
            // browser is always up to date, so make a health check instead
            return this.healthCheck().then(() => {
                this.versionDiff.next(VersionDiff.NONE);
                return VersionDiff.NONE;
            });
        }

        const localVersion = await this.localVersion();
        const remoteVersion = await this.remoteVersion();
        if (!environment.production) {
            console.table({'client': localVersion, 'server': remoteVersion});
        }
        const diff = this.compareVersions(localVersion, remoteVersion);
        this.versionDiff.next(diff);
        return diff;
    }

    async openStorePage() {
        let appId;
        if (this.platformService.isAndroid()) {
            appId = 'hu.astron.mfgk.phonee';
        } else {
            appId = 'id1535966387';
        }
        return Market.open(appId)
            .catch((error) => {
                console.error('An error occurred when trying to open store page: {}', error);
            });
    }

    private compareVersions(localVersion: string, remoteVersion: string): VersionDiff {
        if (compareVersions.compare(localVersion, remoteVersion, '>')) {
            const logger = environment.production ? console.error : console.warn;
            logger('Client app is newer than server.');
            return VersionDiff.NEWER;
        } else if (compareVersions.compare(localVersion, remoteVersion, '<')) {
            console.warn(`Client app is older than server`);
            switch (semverDiff(localVersion, remoteVersion)) {
                case 'major':
                case 'premajor':
                case 'minor':
                case 'preminor':
                    this.alertController.create({
                        header: this.i18n.translate('alert_warning'),
                        message: this.i18n.translate('alert_version_outdated'),
                        buttons: [this.i18n.translate('button_update')]
                    }).then(alert => {
                        alert.onDidDismiss().then(() => {
                            this.openStorePage().finally(() => {
                                // @ts-ignore
                                this.router.navigate(['authentication']);
                            });
                        });
                        return alert.present();
                    });

                    return VersionDiff.MAJOR;
                case 'patch':
                case 'prepatch':
                case 'prerelease':
                case 'build':
                default:
                    return VersionDiff.MINOR;
            }
        } else {
            return VersionDiff.NONE;
        }
    }

    private localVersion(): Promise<string> {
        return this.appVersion.getVersionNumber();
    }

    private remoteVersion(): Promise<string> {
        return this.httpClient.get(`${environment.backendURL}/version/${this.platformService.get()}`, {responseType: 'text'}).toPromise();
    }

    private healthCheck(): Promise<any> {
        return this.httpClient.get(`${environment.backendURL}/health`).toPromise();
    }

}
