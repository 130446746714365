import {Component, OnInit} from '@angular/core';
import {I18nService} from '@astron/i18n';
import {ModalController} from '@ionic/angular';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-informative-modal',
    templateUrl: './informative-modal.component.html',
    styleUrls: ['./informative-modal.component.scss'],
})
export class InformativeModalComponent implements OnInit {

    constructor(private modalController: ModalController,
                private i18nService: I18nService) {
    }

    ngOnInit() {
    }

    onApprove() {
        this.modalController.dismiss(true);
    }

    onDeny() {
        this.modalController.dismiss(false);
    }

    onLinkClick() {
        if (this.i18nService.locale === 'en') {
            window.open(environment.backendHost + '/phonee/client/help-public/Legal_notice_and_data_processing_CEEnergy_Phonee.pdf', '_system', 'location=yes');
        } else {
            window.open(environment.backendHost + '/phonee/client/help-public/Jogi_es_adatkezelesi_tajekoztato_CEEnergy_Phonee.pdf', '_system', 'location=yes');
        }
    }
}
