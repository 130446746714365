import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {I18nModule} from '@astron/i18n';

import {IonicModule} from '@ionic/angular';
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import {GlobalFooterComponent} from '../global/global-footer/global-footer.component';
import {GlobalHeaderComponent} from '../global/global-header/global-header.component';

import {AuthenticationPageRoutingModule} from './authentication-routing.module';

import {AuthenticationPage} from './authentication.page';
import {InformativeModalComponent} from './informative-modal/informative-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        AuthenticationPageRoutingModule,
        I18nModule,
        RecaptchaModule,
        RecaptchaFormsModule,
    ],
    exports: [
        GlobalHeaderComponent,
        GlobalFooterComponent
    ],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: '6LcEbgEVAAAAADBQ9HAgqLKNTUIx-p2Q1WpFLitv' } as RecaptchaSettings
        }
    ],
    declarations: [AuthenticationPage, GlobalHeaderComponent, GlobalFooterComponent, InformativeModalComponent],
})
export class AuthenticationPageModule {
}
