import {Injectable} from '@angular/core';
import {Preferences} from '@capacitor/preferences';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    $darkTheme = new BehaviorSubject<boolean>(false);

    get darkTheme() {
        return this.$darkTheme.value;
    }

    set darkTheme(value) {
        document.documentElement.classList.toggle('dark');
        Preferences.set({key: 'darkTheme', value: value.toString()});
        this.$darkTheme.next(value);
    }

    constructor() {
        Preferences.get({key: 'darkTheme'}).then(darkTheme => {
            if (darkTheme.value === 'true') {
                this.darkTheme = true;
            }
        });
    }

}
