import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {AuthService} from '../service/auth/auth.service';
import {PlatformService} from '../service/platform/platform.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private platformService: PlatformService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authService.user;
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }
        if (environment.production && this.platformService.isBrowser()) {
            request = request.clone({
                setHeaders: {
                    isBrowser: `true`
                }
            });
        }
        return next.handle(request);
    }
}
