import {Component, OnInit} from '@angular/core';
import {I18nService} from '@astron/i18n';

@Component({
    selector: 'app-global-footer',
    templateUrl: './global-footer.component.html',
    styleUrls: ['./global-footer.component.scss'],
})
export class GlobalFooterComponent implements OnInit {

    constructor(private i18nService: I18nService) {
    }

    ngOnInit() {
    }

    onClickAstronLogo() {
        window.open('https://www.astron.hu/' + this.i18nService.locale, '_system', 'location=yes');
    }
}
