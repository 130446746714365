import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthenticationPage} from './authentication.page';

const routes: Routes = [
    {
        path: 'lost-password',
        loadChildren: () => import('./lost-password/lost-password.module').then(m => m.LostPasswordPageModule)
    },
    {
        path: 'new-password',
        loadChildren: () => import('./new-password/new-password.module').then(m => m.NewPasswordPageModule)
    },
    {
        path: '',
        component: AuthenticationPage
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthenticationPageRoutingModule {
}
