<ion-header>
    <ion-grid>
        <!--        todo: the column solution is not ideal, especially because on iphone the title gets cut off-->
        <ion-row class="ion-align-items-center">
            <ion-col size="3" class="ion-text-start">
                <ion-buttons>
                    <ion-menu-button [disabled]="menuDisabled"></ion-menu-button>
                    <ion-back-button [hidden]="!showBackButton()" [defaultHref]="getBackButtonRef()"></ion-back-button>
                </ion-buttons>
            </ion-col>
            <ion-col size="6" class="ion-text-center">
                <ion-title>Phonee</ion-title>
            </ion-col>
            <ion-col size="3" class="ion-text-end ion-align-items-center ion-no-padding" style="margin-left: -6px">
                <ion-text (click)="onLocaleSelected('hu')" [class.selected]="isSelected('hu')"
                          class="language-button">HU
                </ion-text>
                <span>|</span>
                <ion-text (click)="onLocaleSelected('en')" [class.selected]="isSelected('en')"
                          class="language-button">EN
                </ion-text>
            </ion-col>
        </ion-row>
    </ion-grid>

</ion-header>
