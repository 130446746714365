<ion-content>
    <ion-row class="ion-align-items-center" style="min-height: 100%">
        <ion-grid fixed="true" id="main-grid">
            <ion-row class="ion-align-items-center ion-justify-content-center">
                <ion-col size-xs="12" size-sm="3" size-md="12">
                    <ion-row>
                        <ion-col size-xs="6" offset-xs="3" size-sm="12" offset-sm="1" size-md="2" offset-md="5">
                            <img [src]="themeService.darkTheme ?  'assets/images/logo_inverse.png' : 'assets/images/logo.png'"/>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col size-xs="12" size-sm="6" size-md="4">
                    <form #authenticationForm="ngForm" (ngSubmit)="onSubmit(authenticationForm)">
                        <ion-row>
                            <ion-col size-xs="8" offset-xs="2" size-sm="10" offset-sm="1">
                                <ion-label position="floating">{{'authentication_user_name'|i18n}}</ion-label>
                                <ion-item>
                                    <ion-input autocomplete="off" name="userName" ngModel #userName="ngModel"
                                               type="text" required pattern="^(?!\s*$).+" enterkeyhint="next" autofocus
                                               [placeholder]="!userName.pristine && userName.invalid ? ('input_required' | i18n)  : ''"
                                    ></ion-input>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col size-xs="8" offset-xs="2" size-sm="10" offset-sm="1">
                                <ion-label position="floating">{{'authentication_password'|i18n}}</ion-label>
                                <ion-item>
                                    <ion-input autocomplete="off" name="password" ngModel #password="ngModel"
                                               type="password" required pattern="^(?!\s*$).+" enterkeyhint="send"
                                               [placeholder]="!password.pristine && password.invalid ? ('input_required' | i18n)  : ''"
                                    ></ion-input>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row [style.cursor]="'pointer'" (click)="presentInformativeModal()">
                            <ion-col size-xs="2" offset-xs="2" offset-sm="1">
                                <ion-checkbox [style.pointer-events]="'none'"
                                              [checked]="termsOfServiceApproved"
                                              [style.opacity]="termsOfServiceApproved ? 1 : 0.5"></ion-checkbox>
                            </ion-col>
                            <ion-col size-xs="7" size-sm="9">
                                <div>
                                    <ion-label
                                            style="text-decoration: underline">{{'terms_of_service_approved'|i18n}}</ion-label>
                                </div>
                            </ion-col>
                        </ion-row>
                        <ion-row class="ion-text-center ion-align-items-center">
                            <ion-col size-xs="2" offset-xs="2" offset-sm="1">
                                <ion-button fill="clear" type="submit"
                                            (click)="submitButtonClicked(authenticationForm.controls.captcha)">
                                    <ion-icon name="log-in"></ion-icon>
                                </ion-button>
                            </ion-col>
                            <ion-col size-xs="2">
                                <ion-button fill="clear" type="button" (click)="onLostPasswordClicked()">
                                    <ion-icon name="lock-open" class="balanced-icon"></ion-icon>
                                </ion-button>
                            </ion-col>
                            <ion-col size-xs="2" *ngIf="updateAvailable | async">
                                <ion-button fill="clear" type="button" (click)="onUpdateClicked()">
                                    <ion-icon name="refresh-circle-outline" class="balanced-icon"></ion-icon>
                                </ion-button>
                            </ion-col>
                        </ion-row>
                        <ion-row class="ion-text-center ion-align-items-center"
                                 *ngIf="authService.captchaNeeded | async">
                            <ion-col class="recaptcha-container">
                                <re-captcha name="captcha" [(ngModel)]="captcha" required></re-captcha>
                            </ion-col>
                        </ion-row>
                    </form>
                </ion-col>
                <ion-col size-xs="12" size-sm="2" size-md="12">
                    <ion-row class="ion-justify-content-center" style="height: 122px">
                        <ion-col size-xs="6" size-sm="12" size-md="2">
                            <img (click)="onClickMfgkLogo()" src="assets/images/mfgk_logo.png"/>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-row>
</ion-content>
