import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth.guard';
import {VersionGuard} from './version.guard';

const routes: Routes = [
    {
        path: 'authentication',
        loadChildren: () => import('./view/authentication/authentication.module').then(m => m.AuthenticationPageModule),
        canActivate: [VersionGuard]
    },
    {
        path: 'maintenance',
        loadChildren: () => import('./view/maintenance/maintenance.module').then(m => m.MaintenancePageModule)
    },
    {
        path: 'forwards',
        loadChildren: () => import('./view/forwards/forwards.module').then(m => m.ForwardsPageModule),
        canActivate: [VersionGuard, AuthGuard]
    },
    {
        path: 'change-password',
        loadChildren: () => import('./view/change-password/change-password.module').then(m => m.ChangePasswordPageModule),
        canActivate: [VersionGuard, AuthGuard]
    },
    {
        path: 'contracts',
        loadChildren: () => import('./view/contracts/contracts.module').then(m => m.ContractsPageModule),
        canActivate: [VersionGuard, AuthGuard]
    },
    {
        path: 'new-contract',
        loadChildren: () => import('./view/new-contract/new-contract.module').then(m => m.NewContractModule),
        canActivate: [VersionGuard, AuthGuard]
    },
    {
        path: 'allocation',
        loadChildren: () => import('./view/allocation/allocation.module').then(m => m.AllocationPageModule),
        canActivate: [VersionGuard, AuthGuard]
    },
    {path: '', redirectTo: 'forwards', pathMatch: 'full'},
    {path: '**', redirectTo: 'forwards'},

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
