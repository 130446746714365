import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {I18nService} from '@astron/i18n';
import {Preferences} from '@capacitor/preferences';
import {FingerprintAIO} from '@ionic-native/fingerprint-aio/ngx';
import {LoadingController, ModalController, Platform} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthenticationResult} from '../../model/AuthenticationResult';
import {LoginInfo} from '../../model/LoginInfo';
import {VersionDiff} from '../../model/VersionDiff';
import {AlertService} from '../../service/alert/alert.service';
import {AuthService} from '../../service/auth/auth.service';
import {PlatformService} from '../../service/platform/platform.service';
import {ThemeService} from '../../service/theme/theme.service';
import {VersionService} from '../../service/version.service';
import {InformativeModalComponent} from './informative-modal/informative-modal.component';

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.page.html',
    styleUrls: ['./authentication.page.scss'],
})
export class AuthenticationPage implements OnInit {

    @ViewChild('authenticationForm') form: NgForm;

    captcha: string;
    termsOfServiceApproved = false;

    get updateAvailable() {
        return this.versionService.versionDiff.pipe(map(value => value === VersionDiff.MINOR));
    }

    private backButton: Subscription;

    constructor(public modalController: ModalController,
                public themeService: ThemeService,
                public authService: AuthService,
                public versionService: VersionService,
                private router: Router,
                private loadingController: LoadingController,
                private i18nService: I18nService,
                private fingerprint: FingerprintAIO,
                private platform: Platform,
                private alertService: AlertService,
                private platformService: PlatformService) {
    }

    ngOnInit() {
        this.initInformativeApproved();
    }

    ionViewWillEnter() {
        this.captcha = null;
        this.form.reset();
        this.authService.loadIsCaptchaNeeded();
    }

    ionViewDidEnter() {
        this.biometricAuth();

        this.backButton = this.platform.backButton.subscribe(() => {
            // @ts-ignore
            navigator.app.exitApp();
        });
    }

    ionViewWillLeave() {
        this.backButton.unsubscribe();
    }

    private biometricAuth() {
        Promise.all([this.versionService.versionDiff.pipe(take(1)).toPromise(), this.authService.loginData, this.fingerprint.isAvailable()])
            .then(([versionDiff, loginData, fingerprint]) => {
                if (versionDiff !== VersionDiff.MAJOR // user will get a different popup
                    && loginData && loginData.value && ['finger', 'face', 'biometric'].some(type => type === fingerprint)) {
                    this.fingerprint.show({
                        disableBackup: true
                    }).then(result => {
                        if (result === 'Success' || result === 'biometric_success') {
                            return this.login(JSON.parse(loginData.value));
                        }
                    });
                }
            });
    }

    async presentInformativeModal() {
        const modal = await this.modalController.create({
            component: InformativeModalComponent,
            cssClass: 'informativeModal'
        });

        modal.onDidDismiss()
            .then(response => {
                if (response.data != null) {
                    this.termsOfServiceApproved = response.data;
                }
            });
        return await modal.present();
    }

    onClickMfgkLogo() {
        window.open('http://www.magyarfoldgazkereskedo.hu/' + '?lang=' + this.i18nService.locale, '_system', 'location=yes');
    }

    onSubmit(authenticationForm: NgForm) {
        if (authenticationForm.valid && this.termsOfServiceApproved) {
            this.login(authenticationForm.value);
        } else {
            this.showValidationResult(authenticationForm);
        }
    }

    private login(loginInfo: LoginInfo) {
        this.loadingController
            .create({message: this.i18nService.translate('alert_logging_in')})
            .then(loadingEl => {
                loadingEl.present();
                this.authService.login(loginInfo)
                    .subscribe((response) => {
                        if (response.result !== AuthenticationResult.SUCCESS) {
                            this.alertService.showAlertWithHeader('authentication_failure',
                                'authentication_' + response.result.toLowerCase());
                            this.captcha = null;
                        } else {
                            if (this.platformService.isMobile()) {
                                this.authService.storeLoginData(loginInfo);
                            }
                            this.storeInformativeApproved();
                            this.router.navigateByUrl('/forwards');
                        }
                    }).add(() => loadingEl.dismiss());
            });
    }

    showValidationResult(authenticationForm: NgForm) {
        const userName = authenticationForm.controls.userName;
        const password = authenticationForm.controls.password;

        if (userName.invalid) {
            userName.reset();
            userName.markAsDirty();
        }
        if (password.invalid) {
            password.reset();
            password.markAsDirty();
        }
        if (!this.termsOfServiceApproved) {
            this.alertService.showAlert('terms_of_service_approved_required');
        }
    }

    initInformativeApproved() {
        Preferences.get({key: 'informativeApproved'}).then(informativeApproved => {
            this.termsOfServiceApproved = JSON.parse(informativeApproved.value);
        });
    }

    private storeInformativeApproved() {
        Preferences.set({key: 'informativeApproved', value: !!this.termsOfServiceApproved + ''});
    }

    onLostPasswordClicked() {
        this.router.navigateByUrl('authentication/lost-password');
    }

    onUpdateClicked() {
        this.versionService.openStorePage();
    }

    submitButtonClicked(captcha: AbstractControl) {
        if (captcha && !captcha.valid) {
            this.alertService.showAlertWithHeader('alert_error', 'alert_captcha_required');
        }
    }
}
