import {Injectable} from '@angular/core';
import {I18nService} from '@astron/i18n';
import {AlertController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(private alertController: AlertController,
                private i18nService: I18nService) {
    }

    showAlert(message: string, translate = true, callback?: () => void) {
        this.alertController.create({
            header: this.i18nService.translate('alert_warning'),
            message: translate ? this.i18nService.translate(message) : message,
            buttons: [this.i18nService.translate('button_close')]
        }).then(alertEl => {
            if (callback) {
                alertEl.onWillDismiss().then(callback);
            }
            return alertEl.present();
        });
    }

    showAlertWithHeader(header: string, message: string) {
        this.alertController.create({
            header: this.i18nService.translate(header),
            message: this.i18nService.translate(message),
            buttons: [this.i18nService.translate('button_close')]
        }).then(alertEl => alertEl.present());
    }

    showCustomAlert(header: string, subheader: string, message: string, buttons) {
        this.alertController.create({
            header: header,
            subHeader: subheader,
            message: message,
            buttons: buttons
        }).then(alertEl => alertEl.present());
    }

    showCustomAlertWithInput(header: string, subheader: string, message: string, buttons, inputs) {
        this.alertController.create({
            header: header,
            subHeader: subheader,
            message: message,
            inputs: inputs,
            buttons: buttons
        }).then(alertEl => alertEl.present());
    }
}
