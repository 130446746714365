import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {I18nService} from '@astron/i18n';
import {Observable} from 'rxjs';
import {skipWhile} from 'rxjs/operators';
import {VersionDiff} from './model/VersionDiff';
import {VersionService} from './service/version.service';

@Injectable({
    providedIn: 'root'
})
export class VersionGuard implements CanActivate {
    i18nReady = false;

    constructor(private versionService: VersionService,
                private i18n: I18nService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkVersion();
    }

    async checkVersion(): Promise<boolean> {
        if (!this.i18nReady) {
            // wait until it has loaded
            this.i18nReady = await this.i18n.ready.pipe(skipWhile(value => !value)).toPromise();
        }
        return this.versionService.checkAppVersion().then(diff => diff !== VersionDiff.MAJOR);
    }

}
