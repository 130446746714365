import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
    selector: 'phonee-refresh-map-modal',
    templateUrl: './refresh-map-modal.component.html',
    styleUrls: ['./refresh-map-modal.component.scss'],
})
export class RefreshMapModalComponent implements OnInit {

    @Input() startDate: string;
    @Input() endDate: string;

    maxDate: string;

    constructor(private modalController: ModalController) {
    }

    ngOnInit() {
        const currentDate = new Date();
        this.maxDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 10)).toISOString();
    }

    onOk() {
        this.modalController.dismiss([this.startDate, this.endDate]);
    }

    onCancel() {
        this.modalController.dismiss();
    }

    canRefresh() {
        return new Date(this.startDate).getTime() <= new Date(this.endDate).getTime();
    }

    startDateSelected() {
        if (new Date(this.startDate).getTime() > new Date(this.endDate).getTime()) {
            this.endDate = this.startDate;
        }
    }

    endDateSelected() {
        if (new Date(this.endDate).getTime() < new Date(this.startDate).getTime()) {
            this.startDate = this.endDate;
        }
    }

}
