import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {PlatformType} from '../../model/PlatformType';

@Injectable({
    providedIn: 'root'
})
export class PlatformService {

    constructor(private platform: Platform) {
    }

    isMobile() {
        return this.isAndroid() || this.isIos();
    }

    isAndroid() {
        return this.platform.is('android');
    }

    isIos() {
        return this.platform.is('ios');
    }

    isBrowser() {
        return this.platform.is('desktop') || this.platform.is('mobileweb');
    }

    get(): PlatformType {
        if (this.isAndroid()) {
            return PlatformType.ANDROID;
        } else {
            return PlatformType.IOS;
        }
    }
}
