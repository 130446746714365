<ion-content>
    <ion-grid class="ion-no-padding ion-no-margin">
        <ion-row class="row-margin">
            <ion-col class="ion-text-center">
                <ion-label class="title-label">
                    {{'terms_of_service_reference'|i18n}}
                </ion-label>
            </ion-col>
        </ion-row>
        <ion-row class="row-margin">
            <ion-col>
                <ion-label>
                    {{'terms_of_service_cookies'|i18n}}
                </ion-label>
            </ion-col>
        </ion-row>
        <ion-row class="row-margin">
            <ion-col class="ion-text-center">
                <ion-label class="link"
                           (click)="onLinkClick()">{{'terms_of_service_legal_information'|i18n}}</ion-label>
            </ion-col>
        </ion-row>
        <ion-row class="row-margin">
            <ion-col>
                <ion-label>
                    {{'terms_of_service_privacy'|i18n}}
                </ion-label>
            </ion-col>
        </ion-row>
        <ion-row class="ion-text-end">
            <ion-col>
                <ion-button fill="clear" class="font-bold cancelButton"
                            (click)="onDeny()">{{'button_deny'|i18n|uppercase}}</ion-button>
                <ion-button fill="clear" class="font-bold"
                            (click)="onApprove()">{{'button_approve'|i18n|uppercase}}</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
