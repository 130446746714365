import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AlertService} from '../service/alert/alert.service';
import {AuthService} from '../service/auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private alertService: AlertService,
                private router: Router,
                private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((response: HttpErrorResponse) => {
                const error = this.convertIfNeeded(response.error);
                if (error && error.message) {
                    this.alertService.showAlertWithHeader(error.title || 'alert_error', error.message);
                } else {
                    switch (response.status) {
                        case 0:
                        case 503:
                            this.router.navigateByUrl('/maintenance');
                            break;
                        case 401:
                            this.authService.logout();
                            this.alertService.showAlert('network_authentication_error');
                            this.router.navigateByUrl('/authentication');
                            break;
                        case 500:
                            this.alertService.showAlert('network_dm_server_error');
                            break;
                        default:
                    }
                }
                return throwError(response);
            })
        );
    }

    convertIfNeeded(error) {
        // when downloading a file directly, the responseType has to be set to ArrayBuffer
        // then, by default, the error body will also be handled as that, so this is a workaround
        if (error instanceof ArrayBuffer) {
            const decoded = String.fromCharCode.apply(null, new Uint8Array(error));
            return JSON.parse(decoded);
        } else {
            return error;
        }
    }
}
